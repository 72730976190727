.row1 {
	/* background-color: hotpink; */
	/* margin-top: 10px;
	margin-bottom: 10px; */
	max-width: 75vw;
	margin: auto;
}

.row2 {
	/* background-color: lightcyan; */
	/* margin: 10px; */
	max-width: 75vw;
	margin: auto;
}

.recent-card-front {
	max-width: 400px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.recent-card-back {
	max-width: 400px;
	min-height: 252px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.outer-recent {
	/* background-color: #7510f7; */
	width: 100vw;
	margin-top: -180px;
}

.recent-title {
	color: white;
	margin-bottom: 20px;
}

.recent-card-title {
	padding-top: 8px;
}

/* .recent-card-back-text {
	text-align: center;
	vertical-align: middle;
} */

.footer {
	background-color: #7510f7;
	color: white;
}

.linkedin {
	margin: 10px;
}

.github {
	margin: 10px;
}

.footer-upper-text {
	padding-top: 50px;
}

.footer-button-group {
	margin: auto;
}

.footer-button-row {
	padding-top: 10px;
	margin-bottom: 50px;
}

.footer-text-row {
	justify-content: center;
	text-align: center;
}

.row1 {
	margin: 10px;
}

.row2 {
	margin: 10px;
}
.row3 {
	margin: 10px;
}

.proj1 {
	margin: 10px;
}

.proj2 {
	margin: 10px;
}
.proj3 {
	margin: 10px;
}
.proj4 {
	margin: 10px;
}
.proj5 {
	margin: 10px;
}
.proj6 {
	margin: 10px;
}
.proj7 {
	margin: 10px;
}

/* .project-card {
	max-width: 20rem;
	overflow: hidden;
	position: relative;
	display: inline-block;
} */

.previous-card-front {
	max-width: 400px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	/* min-height: 275px; */
}

.previous-card-back {
	max-width: 400px;
	min-height: 252px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.outer-container {
	width: 100vw;
	margin-top: -170px;
	margin-bottom: 100px;
}

.projects-title {
	color: white;
}

.projects-card-title {
	padding-top: 8px;
}

.about-container {
	background-color: #7510f7;
	width: 100vw;
	margin-top: 40px;
	padding-top: 120px;
	padding-bottom: 250px;
}

.words {
	color: white;
	margin-left: 320px;
	margin-right: 320px;
}

.skills-card {
	width: 18rem;
	color: black;
}

.skill-icon {
	margin: auto;
}

.skills-card-outer {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	/* text-align: center; */
	background-color: #7510f7;
	color: white;
	min-width: 100vw;
	padding-top: 70px;
	padding-bottom: 250px;
}

.skills-card-group {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

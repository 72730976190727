.home-image {
	margin-bottom: 80px;
}

.title-words {
	margin: 10px;
}

.sub-words {
	margin: 10px;
}

.bottom-home-text {
	max-width: 750px;
	margin: 0px auto 120px auto;
}

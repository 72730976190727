.header-button {
	color: #7510f7;
	border-color: #7510f7;
	background-color: white;
}
.header-button:hover {
	color: white;
	border-color: #7510f7;
	background-color: #7510f7;
}

.header-wrapper {
	background-color: white;
	margin-top: 10px;
	margin-left: 100px;
	margin-right: 100px;
}
